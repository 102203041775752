import React from 'react';
import './OurServices.scss';

const OurServices = () => {
    return (
        <div className='our-services mt-md-5 mt-4'>
            <div className='container'>

                <h2 className='text-center'> Why Choose Us ? </h2>
                <p className='text-center'> ‘Because Great ideas need a great place to showcase’ </p>

            </div>
        </div>
    )
}

export default OurServices;